import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Andrew Leadbeater', // e.g: 'Name | Developer'
  lang: 'English', // e.g: en, es, fr, jp
  description: 'Welcome to my personal resume', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Andrew Leadbeater',
  subtitle: 'Welcome to my personal portfolio',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'Hey there !',
  paragraphTwo:
    'I am an experienced Support Analyst with a demonstrated long track history of working with information technology, below are some of my personal projects i have been working on',
  paragraphThree:
    'Please feel free to view my resume to find out more about my current and previous employment history',
  resume:
    'https://firebasestorage.googleapis.com/v0/b/leadbeater-792b6.appspot.com/o/CVPDFLRGE.pdf?alt=media&token=14b677ed-12f8-4578-9ca5-3024f3f553c1', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'tc_img.JPG',
    title: 'Teams Clean',
    info:
      'Teams Clean is a simple application to clean Microsoft Teams Cache folders, initally designed to combat laggy user experience when working from home',
    info2: 'Developed for Windows 10 developed using Visual Code ',
    url: 'https://github.com/stratuscodelab/TeamsClean',
    repo: 'https://github.com/stratuscodelab/TeamsClean', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Google_clone.png',
    title: 'Google Clone using ReactJS',
    info: 'A working Google search engine created using ReactJS and google search API',
    info2: 'Project is currently active on Firebase',
    url: 'https://clone-30422.web.app/',
    repo: 'https://github.com/stratuscodelab/Google-Clone', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Instagram_Clone_2.png',
    title: 'Instagram Clone using ReactJS',
    info:
      'A working example of Instagram basics or user account creation and image uploading using live databases',
    info2: 'Website is currently active in Firebase hosting',
    url: 'https://instagram-clone-react-a24c8.web.app/',
    repo: 'https://github.com/stratuscodelab/Instagram-clone', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'ToDo_react.png',
    title: 'ToDo Full Stack CRUD application using ReactJS',
    info: 'A realtime database that can be edited and updated from multiple devices',
    info2: 'Website is currently active in Firebase hosting',
    url: 'https://todo-app-9c08a.web.app/',
    repo: 'https://github.com/stratuscodelab/React-ToDo', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'stratuslabs@outlook.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    /*    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    */
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/andrew-leadbeater-32816591/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/stratuscodelab',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
